import { ResponsiveMapType } from './types'

export const twPadding: ResponsiveMapType = {
  sm: {
    sm: 'ds-px-1 ds-py-2',
    md: 'ds-px-2 ds-py-4',
    lg: 'ds-px-8 ds-py-8',
    none: 'ds-p-0'
  },
  md: {
    sm: 'md:!ds-px-1 md:!ds-py-2',
    md: 'md:!ds-px-2 md:!ds-py-4',
    lg: 'md:!ds-px-4 md:!ds-py-8',
    none: 'md:!ds-p-0'
  },
  lg: {
    sm: 'lg:!ds-px-1 lg:!ds-py-2',
    md: 'lg:!ds-px-2 lg:!ds-py-4',
    lg: 'lg:!ds-px-4 lg:!ds-py-8',
    none: 'lg:!ds-p-0'
  },
  default: {
    sm: 'ds-px-1 md:ds-py-2',
    md: 'ds-px-2 md:ds-py-4',
    lg: 'ds-px-4 md:ds-py-6',
    vertical: 'ds-py-2 md:ds-py-4',
    horizontal: 'ds-px-2 md:ds-px-4',
    none: '!ds-p-0'
  }
}

export default twPadding
