import type { ResponsiveOptions } from './types'
import type { SpanTypes } from './style/types'
import { twCols } from './style/cols'

/**
 * This is a function which can take either an object
 * or a number and return a string with correct css classnames.
 *
 * @param {object | number} spanOptions - A object param
 * @return {string} A string with correct css classnames
 *
 * @example
 * getColsClasses({ sm: 1, md: 2, lg: 3 })
 * // => 'ds-grid-cols-1 md:!ds-grid-cols-2 lg:!ds-grid-cols-3'
 *
 */
export const getColsClasses = (
  spanOptions: SpanTypes | ResponsiveOptions | number
) => {
  switch (typeof spanOptions) {
    case 'number': {
      return twCols.sm && twCols.sm[spanOptions as keyof typeof twCols.sm]
    }
    case 'object': {
      const spanClasses = new Array<string>()
      Object.keys(spanOptions).forEach((key) => {
        const selectedSize = { ...twCols[key as keyof typeof twCols] }
        const selectedValue = spanOptions[key as keyof typeof spanOptions]
        const selectedOption =
          selectedSize[selectedValue as keyof typeof selectedSize]

        selectedOption && spanClasses.push(selectedOption)
      })
      return spanClasses.filter(Boolean).join(' ')
    }
    default:
      return 'default'
  }
}
