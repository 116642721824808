import { SpanMaps } from './types'

export const twColStart: SpanMaps = {
  sm: {
    1: 'ds-col-start-1',
    2: 'ds-col-start-2',
    3: 'ds-col-start-3',
    4: 'ds-col-start-4',
    5: 'ds-col-start-5',
    6: 'ds-col-start-6',
    7: 'ds-col-start-7',
    8: 'ds-col-start-8',
    9: 'ds-col-start-9',
    10: 'ds-col-start-10',
    11: 'ds-col-start-11',
    12: 'ds-col-start-12'
  },
  md: {
    1: 'md:!ds-col-start-1',
    2: 'md:!ds-col-start-2',
    3: 'md:!ds-col-start-3',
    4: 'md:!ds-col-start-4',
    5: 'md:!ds-col-start-5',
    6: 'md:!ds-col-start-6',
    7: 'md:!ds-col-start-7',
    8: 'md:!ds-col-start-8',
    9: 'md:!ds-col-start-9',
    10: 'md:!ds-col-start-10',
    11: 'md:!ds-col-start-11',
    12: 'md:!ds-col-start-12'
  },
  lg: {
    1: 'lg:!ds-col-start-1',
    2: 'lg:!ds-col-start-2',
    3: 'lg:!ds-col-start-3',
    4: 'lg:!ds-col-start-4',
    5: 'lg:!ds-col-start-5',
    6: 'lg:!ds-col-start-6',
    7: 'lg:!ds-col-start-7',
    8: 'lg:!ds-col-start-8',
    9: 'lg:!ds-col-start-9',
    10: 'lg:!ds-col-start-10',
    11: 'lg:!ds-col-start-11',
    12: 'lg:!ds-col-start-12'
  }
}

export default twColStart
