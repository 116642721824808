import type { ResponsiveOptions } from './types'
import type { SpanTypes } from './style/types'
import { twColStart } from './style/colStart'

/**
 * This is a function which can take either an object
 * or a number and return a string with correct css classnames.
 *
 * @param {object | number} getColStartClasses - A object param
 * @return {string} A string with correct css classnames
 *
 * @example
 * getColStartClasses(2)
 * // => 'ds-col-start-2'
 *
 */
export const getColStartClasses = (
  offsetOptions: SpanTypes | ResponsiveOptions | number
) => {
  switch (typeof offsetOptions) {
    case 'number': {
      return (
        twColStart.sm &&
        twColStart.sm[offsetOptions as keyof typeof twColStart.sm]
      )
    }
    case 'object': {
      const spanClasses = new Array<string>()
      Object.keys(offsetOptions).forEach((key) => {
        const selectedSize = { ...twColStart[key as keyof typeof twColStart] }
        const selectedValue = offsetOptions[key as keyof typeof offsetOptions]
        const selectedOption =
          selectedSize[selectedValue as keyof typeof selectedSize]
        selectedOption && spanClasses.push(selectedOption)
      })
      return spanClasses.filter(Boolean).join(' ')
    }
    default:
      return 'default'
  }
}

export default getColStartClasses
