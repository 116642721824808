import { ResponsiveMapType } from './types'

export const twMargins: ResponsiveMapType = {
  sm: {
    sm: 'ds-mx-2',
    md: 'ds-mx-4',
    lg: 'ds-mx-8',
    none: 'ds-mx-0'
  },
  md: {
    sm: 'md:!ds-mx-2',
    md: 'md:!ds-mx-4',
    lg: 'md:!ds-mx-8',
    none: 'md:!ds-mx-0'
  },
  lg: {
    sm: 'lg:!ds-mx-2',
    md: 'lg:!md:!ds-mx-4',
    lg: 'lg:!ds-mx-8',
    none: 'lg:!ds-mx-0'
  },
  default: {
    sm: 'ds-mx-2',
    md: 'ds-mx-4',
    lg: 'ds-mx-8',
    vertical: 'ds-my-2',
    horizontal: 'ds-mx-2',
    none: 'ds-mx-0'
  }
}

export default twMargins
