import React, { ElementType } from 'react'
import clsx from 'clsx'
import { getMarginClasses, getPaddingClasses } from '@sainsburys-tech/style'

import type { ContainerProps } from './Container.types'

import { packageDetails } from '../ops/utils/packageDetails'

const overflowXAuto = {
  xs: 'xs:ds-overflow-x-auto',
  sm: 'sm:ds-overflow-x-auto',
  md: 'md:ds-overflow-x-auto',
  lg: 'lg:ds-overflow-x-auto'
}

const defaultElement = 'span'

/**
 * The `Container` component is a wrapper for the `GridWrapper` component.
 * It provides a max-width and horizontal padding to the grid.
 * @component
 */
export const Container = <T extends ElementType = typeof defaultElement>({
  as,
  children,
  className,
  fluid,
  margin,
  size,
  padding,
  overflow,
  testid,
  dataPkgId,
  ...rest
}: ContainerProps<T>) => {
  const Component = as || 'div'
  return (
    <Component
      className={clsx(
        'ds-o-container',
        fluid && 'ds-o-container--fluid',
        size === 'sm' && 'ds-o-container__sm',
        size === 'md' && 'ds-o-container__md',
        size === 'lg' && 'ds-o-container__lg',
        margin && getMarginClasses(margin),
        padding && getPaddingClasses(padding),
        overflow && 'ds-max-w-full',
        overflow && typeof overflow === 'string' ? overflowXAuto[overflow] : '',
        overflow && typeof overflow === 'boolean' && 'ds-overflow-x-auto',
        className
      )}
      {...rest}
      data-testid={testid}
      data-pkgid={dataPkgId || `${packageDetails.get()}-container`}
    >
      {overflow
        ? React.Children.map(
            children as React.ReactElement | Array<React.ReactElement>,
            (child: React.ReactElement) => {
              return React.cloneElement(child, {
                ...child.props,
                overflow: overflow || child.props.overflow
              })
            }
          )
        : children}
    </Component>
  )
}

Container.displayName = 'Container'

Container.defaultProps = {
  as: 'div'
}

export default Container
