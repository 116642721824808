import type { SizingTypes, ResponsiveOptions } from './types'
import { twMargins } from './style'

const defaultMarginCss: string = `${twMargins?.sm?.sm} ${twMargins?.md?.md}`

/**
 * This is a function which can take either an object
 * string or boolean and return a string with correct
 * css classnames.
 *
 * @param {object | string | number} marginOptions - A object param
 * @return {string} A string with correct css classnames
 *
 * @example
 * getMarginClasses({ sm: 'none', md: 'sm', lg: 'none' })
 * // => 'ds-mx-0 md:!ds-mx-2 lg:!ds-mx-0'
 * getMarginClasses(true)
 * // => 'ds-mx-2 md:!ds-mx-4'
 * getMarginClasses('sm')
 * // => 'ds-mx-2'
 */
export const getMarginClasses = (
  marginOptions: SizingTypes | ResponsiveOptions | 'vertical' | 'horizontal'
) => {
  switch (typeof marginOptions) {
    case 'boolean': {
      return marginOptions ? defaultMarginCss : `${twMargins?.default?.none}`
    }
    case 'string': {
      return twMargins.default && twMargins.default[marginOptions]
    }
    case 'object': {
      const gutterClasses = new Array<string>()
      Object.keys(marginOptions).forEach((key) => {
        const selectedSize = twMargins[key as keyof typeof twMargins]
        const selectedValue = marginOptions[key as keyof typeof marginOptions]
        const selectedOption =
          selectedSize &&
          selectedSize[selectedValue as keyof typeof selectedSize]

        selectedOption && gutterClasses.push(selectedOption as string)
      })

      return gutterClasses.filter(Boolean).join(' ')
    }
    default:
      return defaultMarginCss
  }
}
