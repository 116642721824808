import type { ResponsiveOptions } from './types'
import type { SpanTypes } from './style/types'
import { twColSpan } from './style/colSpan'

/**
 * This is a function which can take either an object
 * or a number and return a string with correct css classnames.
 *
 * @param {object | number} spanOptions - A object param
 * @return {string} A string with correct css classnames
 *
 * @example
 * getColSpanClasses(2)
 * // => 'ds-col-span-2'
 *
 */
export const getColSpanClasses = (
  spanOptions: SpanTypes | ResponsiveOptions | number
) => {
  switch (typeof spanOptions) {
    case 'number': {
      return (
        twColSpan.sm && twColSpan.sm[spanOptions as keyof typeof twColSpan.sm]
      )
    }
    case 'object': {
      const spanClasses = new Array<string>()
      Object.keys(spanOptions).forEach((key) => {
        const selectedSize = { ...twColSpan[key as keyof typeof twColSpan] }
        const selectedValue = spanOptions[key as keyof typeof spanOptions]
        const selectedOption =
          selectedSize[selectedValue as keyof typeof selectedSize]
        selectedOption && spanClasses.push(selectedOption as string)
      })
      return spanClasses.filter(Boolean).join(' ')
    }
    default:
      return 'default'
  }
}
