import { SpanMaps } from './types'

export const twRowSpan: SpanMaps = {
  sm: {
    1: 'ds-row-span-1',
    2: 'ds-row-span-2',
    3: 'ds-row-span-3',
    4: 'ds-row-span-4',
    5: 'ds-row-span-5',
    6: 'ds-row-span-6',
    7: 'ds-row-span-7',
    8: 'ds-row-span-8',
    9: 'ds-row-span-9',
    10: 'ds-row-span-10',
    11: 'ds-row-span-11',
    12: 'ds-row-span-12'
  },
  md: {
    1: 'md:!ds-row-span-1',
    2: 'md:!ds-row-span-2',
    3: 'md:!ds-row-span-3',
    4: 'md:!ds-row-span-4',
    5: 'md:!ds-row-span-5',
    6: 'md:!ds-row-span-6',
    7: 'md:!ds-row-span-7',
    8: 'md:!ds-row-span-8',
    9: 'md:!ds-row-span-9',
    10: 'md:!ds-row-span-10',
    11: 'md:!ds-row-span-11',
    12: 'md:!ds-row-span-12'
  },
  lg: {
    1: 'lg:!ds-row-span-1',
    2: 'lg:!ds-row-span-2',
    3: 'lg:!ds-row-span-3',
    4: 'lg:!ds-row-span-4',
    5: 'lg:!ds-row-span-5',
    6: 'lg:!ds-row-span-6',
    7: 'lg:!ds-row-span-7',
    8: 'lg:!ds-row-span-8',
    9: 'lg:!ds-row-span-9',
    10: 'lg:!ds-row-span-10',
    11: 'lg:!ds-row-span-11',
    12: 'lg:!ds-row-span-12'
  }
}

export default twRowSpan
