import type { GridAutoColMap } from './types'
/**
 * @see https://tailwindcss.com/docs/grid-auto-columns
 */
export const twAutoCols: GridAutoColMap = {
  auto: 'ds-auto-cols-auto',
  min: 'ds-auto-cols-min',
  max: 'ds-auto-cols-max',
  fr: 'ds-auto-cols-fr'
}

export default twAutoCols
