import type { GridAutoFlowMap } from './types'
/**
 * @see https://tailwindcss.com/docs/grid-auto-flow
 */
export const twAutoFlow: GridAutoFlowMap = {
  row: 'ds-grid-flow-row',
  col: 'ds-grid-flow-col',
  dense: 'ds-grid-flow-dense',
  'row dense': 'grid-flow-row-dense',
  'column dense': 'grid-flow-col-dense'
}

export default twAutoFlow
