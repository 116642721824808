import type { GapTypes, ResponsiveOptions } from './types'
import { twGap } from './style/gap'

/**
 * A function which can take either an object or a string
 * and return a string with correct css classnames.
 *
 * @param {object | string} gapOptions - A object parameter
 * or string
 * @return {string} A string with correct css classnames
 *
 * @example
 * getGapClasses({ sm: 'lg', md: 'sm', lg: 'lg' })
 * // => '!ds-gap-lg md:!ds-gap-sm lg:!ds-gap-lg'
 *
 */
export const getGapClasses = (
  gapOptions: GapTypes | ResponsiveOptions | string
) => {
  switch (typeof gapOptions) {
    case 'string': {
      return (
        twGap.default && twGap.default[gapOptions as keyof typeof twGap.default]
      )
    }
    case 'object': {
      const gapClasses = new Array<string>()
      Object.keys(gapOptions).forEach((key) => {
        const selectedSize = twGap[key as keyof typeof twGap]
        const selectedValue = gapOptions[key as keyof typeof gapOptions]
        const selectedOption =
          selectedSize &&
          selectedSize[selectedValue as keyof typeof selectedSize]

        selectedOption && gapClasses.push(selectedOption as string)
      })
      return gapClasses.filter(Boolean).join(' ')
    }
    default:
      return 'default'
  }
}
