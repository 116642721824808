import React, { ElementType } from 'react'
import clsx from 'clsx'
import {
  getColEndClasses,
  getColStartClasses,
  getColSpanClasses,
  getRowSpanClasses
} from '@sainsburys-tech/style'

import type { GridItemProps } from './GridItem.types'

import { packageDetails } from '../ops/utils/packageDetails'

const defaultElement = 'div'

/**
 * The `GridItem` is the item which can be placed inside a `GridWrapper` component.
 * It has a number of props which can be used to control the layout of the individual item.
 * The yellow test highlights the current breakpoint.
 *
 * ```
 * npm install @sainsburys-tech/grid
 * ```
 * @component
 */
export const GridItem = <T extends ElementType = typeof defaultElement>({
  as,
  children,
  className,
  hidden,
  colSpan,
  rowSpan,
  start,
  end,
  testid,
  dataPkgId,
  ...props
}: GridItemProps<T>) => {
  const Component = as || 'div'
  return (
    <Component
      className={clsx(
        'ds-o-grid-item',
        colSpan && getColSpanClasses(colSpan),
        rowSpan && getRowSpanClasses(rowSpan),
        start && getColStartClasses(start),
        end && getColEndClasses(end),
        hidden !== undefined && hidden && 'ds-sr-only',
        className
      )}
      {...props}
      data-testid={testid}
      data-pkgid={dataPkgId || `${packageDetails.get()}-grid-item`}
    >
      {children}
    </Component>
  )
}

GridItem.displayName = 'GridItem'

GridItem.defaultProps = {
  as: 'div'
}

export default GridItem
