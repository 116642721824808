import { SpanMaps } from './types'

export const twCols: SpanMaps = {
  sm: {
    1: 'ds-grid-cols-1',
    2: 'ds-grid-cols-2',
    3: 'ds-grid-cols-3',
    4: 'ds-grid-cols-4',
    5: 'ds-grid-cols-5',
    6: 'ds-grid-cols-6',
    7: 'ds-grid-cols-7',
    8: 'ds-grid-cols-8',
    9: 'ds-grid-cols-9',
    10: 'ds-grid-cols-10',
    11: 'ds-grid-cols-11',
    12: 'ds-grid-cols-12'
  },
  md: {
    1: 'md:!ds-grid-cols-1',
    2: 'md:!ds-grid-cols-2',
    3: 'md:!ds-grid-cols-3',
    4: 'md:!ds-grid-cols-4',
    5: 'md:!ds-grid-cols-5',
    6: 'md:!ds-grid-cols-6',
    7: 'md:!ds-grid-cols-7',
    8: 'md:!ds-grid-cols-8',
    9: 'md:!ds-grid-cols-9',
    10: 'md:!ds-grid-cols-10',
    11: 'md:!ds-grid-cols-11',
    12: 'md:!ds-grid-cols-12'
  },
  lg: {
    1: 'lg:!ds-grid-cols-1',
    2: 'lg:!ds-grid-cols-2',
    3: 'lg:!ds-grid-cols-3',
    4: 'lg:!ds-grid-cols-4',
    5: 'lg:!ds-grid-cols-5',
    6: 'lg:!ds-grid-cols-6',
    7: 'lg:!ds-grid-cols-7',
    8: 'lg:!ds-grid-cols-8',
    9: 'lg:!ds-grid-cols-9',
    10: 'lg:!ds-grid-cols-10',
    11: 'lg:!ds-grid-cols-11',
    12: 'lg:!ds-grid-cols-12'
  }
}
