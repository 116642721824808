import type { SizingTypes, ResponsiveOptions } from './types'
import { twPadding } from './style/padding'

const defaultPaddingCss: string = `${twPadding?.sm?.sm} ${twPadding?.md?.md}`

/**
 * This is a function which can take either an object
 * string or boolean and return a string with correct
 * css classnames.
 *
 * @param {object | string | boolean} marginOptions - A object param
 * @return {string} A string with correct css classnames
 *
 * @example
 * getPaddingClasses({ sm: 'none', md: 'sm', lg: 'none' })
 * // => 'ds-p-0 md:!ds-px-1 md:!ds-py-2 lg:!ds-p-0'
 * getPaddingClasses(true)
 * // => 'ds-px-1 ds-py-2 md:!ds-px-2 md:!ds-py-4'
 * getPaddingClasses('sm')
 * // => 'ds-px-1 md:ds-py-2'
 */
export const getPaddingClasses = (
  paddingOptions: SizingTypes | ResponsiveOptions | 'vertical' | 'horizontal'
) => {
  switch (typeof paddingOptions) {
    case 'boolean': {
      return paddingOptions ? defaultPaddingCss : `${twPadding?.default?.none}`
    }
    case 'string': {
      return twPadding.default && twPadding.default[paddingOptions]
    }
    case 'object': {
      const paddingClasses = Array<string>()
      Object.keys(paddingOptions).forEach((key) => {
        const selectedSize = twPadding[key as keyof typeof twPadding]
        const selectedValue = paddingOptions[key as keyof typeof paddingOptions]
        const selectedOption =
          selectedSize &&
          selectedSize[selectedValue as keyof typeof selectedSize]

        selectedOption && paddingClasses.push(selectedOption as string)
      })
      return paddingClasses.filter(Boolean).join(' ')
    }
    default:
      return defaultPaddingCss
  }
}
