import type { ResponsiveOptions } from './types'
import type { SpanTypes } from './style/types'
import { twColEnd } from './style/colEnd'

/**
 * This is a function which can take either an object
 * or a number and return a string with correct css classnames.
 *
 * @param {object | number} getColEndClasses - A object param
 * @return {string} A string with correct css classnames
 *
 * @example
 * getColEndClasses(2)
 * // => 'ds-col-end-2'
 *
 */
export const getColEndClasses = (
  insetOptions: SpanTypes | ResponsiveOptions | number
) => {
  switch (typeof insetOptions) {
    case 'number': {
      return (
        twColEnd.sm && twColEnd.sm[insetOptions as keyof typeof twColEnd.sm]
      )
    }
    case 'object': {
      const spanClasses = new Array<string>()
      Object.keys(insetOptions).forEach((key) => {
        const selectedSize = { ...twColEnd[key as keyof typeof twColEnd] }
        const selectedValue = insetOptions[key as keyof typeof insetOptions]
        const selectedOption =
          selectedSize[selectedValue as keyof typeof selectedSize]
        selectedOption && spanClasses.push(selectedOption)
      })
      return spanClasses.filter(Boolean).join(' ')
    }
    default:
      return 'default'
  }
}

export default getColEndClasses
