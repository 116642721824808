import { SpanMaps } from './types'

export const twColEnd: SpanMaps = {
  sm: {
    1: 'ds-col-end-1',
    2: 'ds-col-end-2',
    3: 'ds-col-end-3',
    4: 'ds-col-end-4',
    5: 'ds-col-end-5',
    6: 'ds-col-end-6',
    7: 'ds-col-end-7',
    8: 'ds-col-end-8',
    9: 'ds-col-end-9',
    10: 'ds-col-end-10',
    11: 'ds-col-end-11',
    12: 'ds-col-end-12'
  },
  md: {
    1: 'md:!ds-col-end-1',
    2: 'md:!ds-col-end-2',
    3: 'md:!ds-col-end-3',
    4: 'md:!ds-col-end-4',
    5: 'md:!ds-col-end-5',
    6: 'md:!ds-col-end-6',
    7: 'md:!ds-col-end-7',
    8: 'md:!ds-col-end-8',
    9: 'md:!ds-col-end-9',
    10: 'md:!ds-col-end-10',
    11: 'md:!ds-col-end-11',
    12: 'md:!ds-col-end-12'
  },
  lg: {
    1: 'lg:!ds-col-end-1',
    2: 'lg:!ds-col-end-2',
    3: 'lg:!ds-col-end-3',
    4: 'lg:!ds-col-end-4',
    5: 'lg:!ds-col-end-5',
    6: 'lg:!ds-col-end-6',
    7: 'lg:!ds-col-end-7',
    8: 'lg:!ds-col-end-8',
    9: 'lg:!ds-col-end-9',
    10: 'lg:!ds-col-end-10',
    11: 'lg:!ds-col-end-11',
    12: 'lg:!ds-col-end-12'
  }
}

export default twColEnd
