import React, { ElementType } from 'react'
import clsx from 'clsx'
import {
  getColsClasses,
  getGapClasses,
  getAutoColsClasses,
  getAutoFlowClasses
} from '@sainsburys-tech/style'

import type { GridWrapperProps } from './GridWrapper.types'

import { packageDetails } from '../ops/utils/packageDetails'

const gridFlowCol = {
  xs: 'xs:ds-grid-flow-col',
  sm: 'sm:ds-grid-flow-col',
  md: 'md:ds-grid-flow-col',
  lg: 'lg:ds-grid-flow-col'
}

const defaultElement = 'div'

/**
 * `GridWrapper` will render any `GridItems` (children) into a Grid.
 * By default, these items will lay themselves out into equal sizes - up to 12 columns.
 * All items will be rendered on a single row.
 * ```
 * npm install @sainsburys-tech/grid
 * ```
 * @component
 */
export const GridWrapper = <T extends ElementType = typeof defaultElement>({
  as,
  center,
  children,
  className,
  cols,
  colSize,
  gap,
  flow,
  stack,
  testid,
  overflow,
  dataPkgId
}: GridWrapperProps<T>) => {
  const Component = as || 'div'
  return (
    <Component
      className={clsx(
        'ds-o-grid',
        overflow && typeof overflow === 'string' && gridFlowCol[overflow],
        overflow && typeof overflow === 'boolean' && 'ds-grid-flow-col',
        [cols, colSize, flow, stack, overflow].every((x) => x === undefined) &&
          'ds-grid-flow-col',
        cols && getColsClasses(cols),
        center && 'ds-o-grid--center',
        gap && getGapClasses(gap),
        colSize && getAutoColsClasses(colSize),
        overflow && colSize === undefined && 'ds-auto-cols-auto',
        flow && getAutoFlowClasses(flow),
        stack && `ds-o-grid--stack__${stack}`,
        className
      )}
      data-testid={testid}
      data-pkgid={dataPkgId || `${packageDetails.get()}-grid-wrapper`}
    >
      {children}
    </Component>
  )
}

GridWrapper.defaultProps = {
  as: 'div'
}

GridWrapper.displayName = 'GridWrapper'

export default GridWrapper
