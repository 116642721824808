import { ResponsiveMapType } from './types'

export const twGap: ResponsiveMapType = {
  sm: {
    sm: '!ds-gap-sm',
    md: '!ds-gap-md',
    lg: '!ds-gap-lg',
    none: '!ds-gap-none'
  },
  md: {
    sm: 'md:!ds-gap-sm',
    md: 'md:!ds-gap-md',
    lg: 'md:!ds-gap-lg',
    none: 'md:!ds-gap-none'
  },
  lg: {
    sm: 'lg:!ds-gap-sm',
    md: 'lg:!ds-gap-md',
    lg: 'lg:!ds-gap-lg',
    none: 'lg:!ds-gap-none'
  },
  default: {
    sm: '!ds-gap-sm',
    md: '!ds-gap-md',
    lg: '!ds-gap-lg',
    none: '!ds-gap-none'
  }
}

export default twGap
